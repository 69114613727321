import Vue from 'vue'

// Make sure to pick a unique name for the flag
// , so it won't conflict with any other mixin.
if (!Vue.__psg_parent_category_getter__) {
  Vue.__psg_parent_category_getter__ = true
  Vue.mixin({
    methods: {
      psgGetParentCategory(categories) {
        if (Array.isArray(categories)) {
          if (categories.length) {
            for (let i = 0; i < categories.length; i++) {
              if (categories[i].parent === 0) {
                return categories[i]
              }
            }
            return categories[0]
          }
          return null
        }
        return categories
      },
    },
  })
}
