export default ({ app }, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  inject('psgChunkArray', (arr = [], chunkSize = 3) => {
    const R = []
    for (let i = 0; i < arr.length; i += chunkSize)
      R.push({
        key: i,
        item: arr.slice(i, i + chunkSize),
      })
    return R
  })
}
