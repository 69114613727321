
import CourseSignUpModal from '@/components/shared/CourseSignUpModal'

export default {
  components: {
    CourseSignUpModal,
  },
  data() {
    return {
      showCourseSignUpModal: false,
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.showCourseSignUpModal ? 'overflow-hidden' : 'overflow-auto',
      },
    }
  },
  created() {
    this.$nuxt.$on('open-course-signup-modal', () => {
      console.log('received event: open-course-signup-modal')
      this.showCourseSignUpModal = true
    })
    this.$nuxt.$on('close-course-signup-modal', () => {
      console.log('received event: close-course-signup-modal')
      this.showCourseSignUpModal = false
    })
  },
}
