
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      processing: false,
      submitted: false,
    }
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.log(e)
    }
  },
  beforeDestroy() {
    this.submitted = false
    this.$recaptcha.destroy()
  },
  methods: {
    async onSubmit() {
      try {
        this.processing = true
        const token = await this.$recaptcha.execute('course_signup')
        console.log('ReCaptcha token:', token)
        const response = await this.$axios.post('/course-signup', {
          referrer: this.$route.path,
          token,
          data: {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            message: this.message,
          },
        })
        if (response.status === 200 && response.data.success) {
          this.processing = false
          this.submitted = true
        } else {
          this.processing = false
        }
      } catch (error) {
        console.log('Submission error:', error)
      }
    },
  },
}
