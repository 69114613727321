
export default {
  data() {
    return {
      showMobileMenu: false,
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.showMobileMenu ? 'overflow-hidden' : 'overflow-auto',
      },
    }
  },
  computed: {
    categories() {
      return this.$config.categories
    },
  },
  watch: {
    $route() {
      this.showMobileMenu = false
    },
  },
}
