
export default {
  data: () => ({
    posts: [],
  }),
  async mounted() {
    await this.getInstagramPosts()
  },
  methods: {
    async getInstagramPosts() {
      try {
        const res = await this.$store.dispatch('getInstagramPosts')
        this.posts = res
      } catch (e) {
        console.log(e)
      }
    },
    getMediaUrl(post) {
      if (!post) return
      switch (post.media_type) {
        case 'IMAGE':
        case 'CAROUSEL_ALBUM':
          return post.media_url
        case 'VIDEO':
          return post.thumbnail_url
        default:
          break
      }
      return null
    },
  },
}
