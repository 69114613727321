export const state = () => ({
  instagram_posts_preloaded: false,
  instagram_posts: [],
  instagram_posts_updated: null,
  top_categories_preloaded: false,
  top_categories: [],
  top_categories_updated: null,
})

export const mutations = {
  instagramPostsPreloaded(state) {
    state.instagram_posts_preloaded = true
  },
  updateInstagramPosts(state, payload) {
    state.instagram_posts = payload
    state.instagram_posts_updated = new Date()
  },
  topCategoriesPreloaded(state) {
    state.top_categories_preloaded = true
  },
  updateTopCategories(state, payload) {
    state.top_categories = payload
    state.top_categories_updated = new Date()
  },
}

export const actions = {
  async getInstagramPosts(context) {
    if (!context.state.instagram_posts_preloaded) {
      const res = await this.$axios.$post(`/get-instagram-posts`, {
        dummy: 'dummy value',
      })
      context.commit('instagramPostsPreloaded')
      if (res.success) {
        context.commit('updateInstagramPosts', res.data)
        return res.data
      }
    }

    // Call fetch again if last fetch more than 10 mins ago
    if (
      context.state.instagram_posts_updated &&
      context.state.instagram_posts_updated <= Date.now() - 600000
    ) {
      console.log('MAKING AXIOS REQUEST TO INSTA FEED')
      const res = await this.$axios.$post(`/get-instagram-posts`)
      if (res.status === 200 && res.data.success) {
        context.commit('updateInstagramPosts', res.data.data)
        return res.data.data
      }
    }
    return context.state.instagram_posts
  },
  async getTopCategories(context) {
    if (!context.state.top_categories_preloaded) {
      const categories = await this.$axios.$get(
        `https://api.plantssogood.com/wp-json/wp/v2/categories?per_page=10&status=publish&page=1&hide_empty=true`
      )
      let categoriesToShow = categories.filter(
        (cat) => cat.acf && cat.acf.show_on_blog_page
      )
      categoriesToShow = await Promise.all(
        categoriesToShow.map(async (category) => {
          const posts = await this.$axios.$get(
            `https://api.plantssogood.com/wp-json/wp/v2/posts?per_page=3&status=publish&page=1&categories=${category.id}`
          )
          return {
            ...category,
            posts,
          }
        })
      )
      context.commit('topCategoriesPreloaded')
      context.commit('updateTopCategories', categoriesToShow)
      return categoriesToShow
    }

    return context.state.top_categories
  },
}
