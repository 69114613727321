import { render, staticRenderFns } from "./FooterLandingPage.vue?vue&type=template&id=f6f147f8&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/opt/build/repo/components/Footer.vue').default})
