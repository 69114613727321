import Vue from 'vue'

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__psg_html_unescaper__) {
  Vue.__psg_html_unescaper__ = true
  Vue.mixin({
    methods: {
      psgUnescapeHtml(str) {
        if (str) return str.replace(/&amp;/g, '&')
        return str
      },
    },
  })
}
