
import CourseSignUpForm from '~/components/shared/CourseSignUpForm'
export default {
  components: {
    CourseSignUpForm,
  },
  methods: {
    closeCourseSignUpModal() {
      this.$nuxt.$emit('close-course-signup-modal')
    },
  },
}
